<template>
  <v-row justify="center">
    <v-dialog v-model="shower"  scrollable  max-width="500px" persistent>
      <v-card>
        <v-card-title >RelationName : {{fileinbox.relation_name}}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">device_hub</v-icon>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-card>
              <v-container fluid >
                  <v-row align="center">
                  <v-col cols="12">
                      <div v-if="loaddataprogress === true" class="text-center">
                        <v-progress-circular
                            indeterminate
                            size="64"
                            :color="color.theme"
                          >
                          <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                          </v-progress-circular>
                      </div>
                      <v-list>
                        <v-subheader>Choose Relation</v-subheader>
                            <v-divider></v-divider>
                        <v-list-item v-for="(item,i) in statusrelation" :key="i">
                          <v-list-item-content class="pl-2">
                            <v-checkbox :color="color.theme" v-model="selected" :label="item" :value="item"></v-checkbox>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                  </v-col>
                  </v-row>
              </v-container>
                    </v-card>
            </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('closedialog'),selected=[]">{{$t('uploadfolder.buttonclose') }}</v-btn>
          <v-btn :color="color.theme" dark @click="loadapi(),selected=[]">{{$t('changeName.submit')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
  export default {
    props:["show","filedata","fileinbox"],
    data () {
      return {
        checkstatus:[],
        selected:[],
        newfile:[],
        statusrelation:[],
        loaddataprogress:true
      }
    },
    computed:{
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
        shower: {
      get() {
        if (this.show === true) {
          this.checkloaddata()
        }
        return this.show;
      },
        },
      colorProgress() {
      return "color:" + this.color.theme + ";";
    }

    },
    methods:{
       async loadapi(){
          let newdata = []
          let payload
            for(let i = 0; i < this.newfile.length;i++ ){
              for(let j =0; j<this.selected.length;j++){
                    if(this.newfile[i].filename === this.selected[j]){
                       newdata.push(this.newfile[i].inbox_id)
                    }
              }
            }
            payload={
                inbox_id_child:newdata,
                account_id:this.dataAccountId,
                inbox_root:this.fileinbox.inbox_id,
                relation_id:this.fileinbox.relation_id
            }
            console.log("data",payload);
            let auth = await gbfGenerate.generateToken();
           await this.axios
                .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/insert_relation_inbox_to_root",payload, { headers: { Authorization: auth.code },},)
                .then(response =>{
                  if (response.data.status === "OK") {
                        Toast.fire({
                            icon: "success",
                            title: "Success"
                         });
                        this.$emit('closedialog')
                    }else{
                        Toast.fire({
                            icon: "error",
                            title: "Error"
                         });
                    }
                    this.$emit('loaddata')
                    
                })
        },
       async checkloaddata(){
          this.statusrelation = []
          let payload
               payload = {
                  limit:"",
                  offset: "",
                  receiver_id:[this.dataAccountActive.business_info["business_id"]],
                  receiver_type:"2",
                  filename:"",
                  fileextension:"",
                  doc_no_start:"",
                  doc_no_end: "",
                  doc_date_start:"",
                  doc_date_end:"",
                  doc_type: "",
                  cre_dtm_start:"",
                  cre_dtm_end:"",
                  sender_name:"",
                  system:this.$route.query.systemid,
                  sellerTaxid:"",
                  sellerBranchid: "",
                  receiver_name: "",
                  receiverTaxid:"",
                  receiverBranchid:"",
                  status:""
          }
          let auth = await gbfGenerate.generateToken();
          this.axios
          .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_file_inbox_child",
          payload,{headers: { Authorization: auth.code },}
          )   
           .then(response=>{
           if (response.data.status === "OK") {
            this.newfile = response.data.result.data
            this.loaddataprogress=false
            for(let i = 0; i <this.newfile.length; i++){
                if(this.newfile[i].relation_status === 2 && this.newfile[i].status === "active"){
                  this.statusrelation.push(this.newfile[i].filename)
                  console.log("statusrelation",this.statusrelation);
                }
            }
          }
          else{

          }
          })   
        }
    }
  }
</script>